import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Theme from './pages/layout/Theme';
import Top from './pages/Top';
import ReqForm from './pages/ReqForm';
import ReqCard from './pages/ReqCard';
import Test from './pages/Test';
import Table from './pages/Table';

const App = () => {
  return (
    <Theme>
      <Router>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/form" element={<ReqForm />} />
          <Route path="/card" element={<ReqCard />} />
          <Route path="/test" element={<Test />} />
          <Route path="/table" element={<Table />} />
          {/* <Route path="/*" element={<Top />} /> */}
        </Routes>
      </Router>
    </Theme>
  );
};

export default App;
