import React from 'react';

import { Avatar, Container, Typography, Box, Card, CardContent, Link, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import Logo from '../image/smile.png';

const Top = () => {
  const flag = true;

  const data = [
    {
      iconPath: '',
      title: 'X',
      user_id: '@kanagawa_soooon',
      link: 'https://x.com/kanagawa_soooon',
      detail: 'test',
    },
    {
      iconPath: '',
      title: 'Instagram',
      user_id: 'test',
      link: 'https://x.com/kanagawa_soooon',

      detail: 'test',
    },
    {
      iconPath: '',
      title: 'Bluesky',
      user_id: 'test',
      link: 'https://x.com/kanagawa_soooon',
      detail: 'test',
    },
  ];

  return (
    <Container sx={{ backgroundColor: flag ? 'gray' : '' }}>
      <Grid2 container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid2 item>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar src={Logo} sx={{ width: 100, height: 100, border: '1px solid #000' }} />
            <Typography component="h1" variant="h6">
              かながわ
            </Typography>
          </Box>
        </Grid2>
      </Grid2>

      {data.map((item) => (
        <Card key={item.user_id} sx={{ mb: 1 }}>
          <CardContent>
            <Grid2 container>
              <Grid2 item>
                <Link href={item.link}>
                  <IconButton>
                    <Avatar src={Logo} sx={{ width: 100, height: 100, border: '1px solid #000' }} variant="rounded" />
                  </IconButton>
                </Link>
                {/* <Avatar src={item.iconPath} variant="rounded" sx={{ width: 60, height: 60, mr: 2 }} /> */}
              </Grid2>
              <Grid2 item>
                <Typography>{item.title}</Typography>
                <Typography>{item.user_id}</Typography>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

export default Top;
