import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

// 新しい RowItem コンポーネント
const RowItem = ({ label, value }) => (
  <Stack direction="row">
    <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
      {label}
    </Typography>
    <Divider orientation="vertical" flexItem />
    <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
      {value}
    </Typography>
  </Stack>
);

const Test = () => {
  return (
    <>
      <Typography component="h1" variant="h4">
        入力フォーム
      </Typography>
      <Box border={1} borderRadius={2} sx={{ borderColor: 'silver', backgroundColor: '#d1e5e8' }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          フォーム1
        </Typography>
        <Divider fullWidth />
        <Box sx={{ backgroundColor: 'white' }}>
          <RowItem label="プロジェクト" value="値" />
          <Divider fullWidth />
          <RowItem label="フェーズ" value="値" />
          <Divider fullWidth />
          <RowItem label="NvBlock名" value="値" />
          <Divider fullWidth />
        </Box>
        <Typography variant="h6" sx={{ p: 2 }}>
          フォーム1
        </Typography>
        <Divider fullWidth />
        <Box sx={{ backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
          <RowItem label="サイズ" value="値" />
          <Divider fullWidth />
          <RowItem label="時間" value="値" />
          {/* 他の RowItem を追加 */}
        </Box>
      </Box>
    </>
  );
};

export default Test;
