import { useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Email as EmailIcon } from '@mui/icons-material';
import { ContentCopy } from '@mui/icons-material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    name: {
      firstName: 'John',
      lastName: 'Doe',
    },
    address: '261 Erdman Ford',
    city: 'East Daphne',
    state: 'Kentucky',
  },
  {
    name: {
      firstName: 'Jane',
      lastName: 'Doe',
    },
    address: '769 Dominic Grove',
    city: 'Columbus',
    state: 'Ohio',
  },
  {
    name: {
      firstName: 'Joe',
      lastName: 'Doe',
    },
    address: '566 Brakus Inlet',
    city: 'South Linda',
    state: 'West Virginia',
  },
  {
    name: {
      firstName: 'Kevin',
      lastName: 'Vandy',
    },
    address: '722 Emie Stream',
    city: 'Lincoln',
    state: 'Nebraska',
  },
  {
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South-CarolinaSouth-CarolinaSouth-CarolinaSouth-CarolinaSouth-Carolina',
  },
];

const Table = () => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name.firstName', //access nested data with dot notation
        header: 'First Name',
      },
      {
        accessorKey: 'name.lastName',
        header: 'Last Name',
        size: 150,
      },
      {
        accessorKey: 'address', //normal accessorKey
        header: 'Address',
        size: 200,
      },
      {
        accessorKey: 'city',
        header: 'City',
        size: 150,
      },
      {
        accessorKey: 'state',
        header: 'State',
        grow: false,
        /*         size: 30, // カラムの幅を100ピクセルに設定
        minSize: 20, // 最小幅を80ピクセルに設定
        maxSize: 50, // 最大幅を120ピクセルに設定
        Footer: <a style={{ color: 'red' }}>State</a>, */
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    icons: {
      // SortDescendingIcon: (props) => (
      //   <IconButton {...props}>
      //     <AcUnitIcon />
      //   </IconButton>
      // ),
    },
    muiTableBodyRowProps: ({ row }) => ({
      onMouseEnter: () => {
        console.log(row);
        console.log(row.index);
        setHoveredRowIndex(row.index);
      },
      onMouseLeave: () => {
        setHoveredRowIndex(null);
      },
    }),
    /* renderTopToolbarCustomActions */
    renderTopToolbarCustomActions: ({ row, table }) => (
      <Stack direction="row" spacing={2}>
        <Button
          color="secondary"
          onClick={() => {
            alert('Create New Account');
          }}
          variant="contained"
        >
          Create Account
        </Button>
        <Button
          color="error"
          /* disabled={!table.getIsSomeRowsSelected()} */
          onClick={() => {
            alert('Delete Selected Accounts');
          }}
          variant="contained"
        >
          Delete Selected Accounts
        </Button>
      </Stack>
    ),
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', // Actionsカラムのヘッダーを空白に設定
        size: 100,
      },
    },

    enableClickToCopy: true,
    enableSorting: true, // ソート機能を有効にする
    /* enableSortingRemoval: false, */
    muiTableHeadCellProps: {
      sx: {
        '& .MuiTableSortLabel-icon': {
          display: 'none', // ソートアイコンを非表示にする
        },
      },
    },

    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', minHeight: '48px' }}>
          {/* minHeightを設定 */}
          {hoveredRowIndex === row.index ? (
            <>
              <IconButton color="primary" onClick={() => {}}>
                <EmailIcon />
              </IconButton>
              <IconButton color="secondary" onClick={() => {}}>
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={() => {}}>
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton style={{ visibility: 'hidden' }}>
                <EmailIcon />
              </IconButton>
              <IconButton style={{ visibility: 'hidden' }}>
                <EditIcon />
              </IconButton>
              <IconButton style={{ visibility: 'hidden' }}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </Box>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};

export default Table;
