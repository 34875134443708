import React from 'react';
import { Box, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material';

const Form = () => {
  return (
    <>
      <Typography component="h1" variant="h4">
        入力フォーム
      </Typography>
      <Box border={1} borderRadius={2} sx={{ borderColor: 'silver', backgroundColor: '#d1e5e8' }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          フォーム1
        </Typography>
        <Divider fullWidth />
        <Box sx={{ backgroundColor: 'white' }}>
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              プロジェクト
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              値
            </Typography>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              フェーズ
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              値
            </Typography>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              NvBlock名
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              値
            </Typography>
          </Stack>
          <Divider fullWidth />
        </Box>
        <Typography variant="h6" sx={{ p: 2 }}>
          フォーム1
        </Typography>
        <Divider fullWidth />
        <Box sx={{ backgroundColor: 'white', backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              サイズ
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              値
            </Typography>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              値
            </Typography>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row">
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />{' '}
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
          <Divider fullWidth />
          <Stack direction="row" sx={{}}>
            <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
              時間
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
              <Divider fullWidth />
              <Typography variant="body1" sx={{ p: 1, width: '250px' }}>
                値
              </Typography>
            </Stack>
          </Stack>
        </Box>
        {/*  <Box sx={{ backgroundColor: 'white', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>*/}
      </Box>
    </>
  );
};

export default Form;
