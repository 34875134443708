import React from 'react';

import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', height: '40px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="body1" color="white" justifyContent="center" textAlign="center">
        Copyright © Apple CORPORATION 2024.
      </Typography>
    </Box>
  );
};

export default Footer;
