import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Grid, Container, Typography, Button } from '@mui/material';

const Top = () => {
  const cardItem = [
    {
      title: 'フォーム',
      path: '/form',
    },
    {
      title: 'プロフィール',
      path: '/card',
    },
    {
      title: 'test',
      path: '/test',
    },
    {
      title: 'table',
      path: '/table',
    },
  ];

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          {cardItem.map((data) => {
            return (
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{data.title}</Typography>
                  </CardContent>
                  <Button variant="contained" component={Link} to={data.path} sx={{ m: 1 }}>
                    Go
                  </Button>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export default Top;
