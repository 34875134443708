import React from 'react';

import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';

const Header = () => {
  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: 'orange' }}>
        <Toolbar variant="dense">
          <IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'black' }}>
              test
            </Typography>
          </IconButton>
          <IconButton>
            <ExitToAppRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
