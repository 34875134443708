import React from 'react';

import Header from './Header';
import Footer from './Footer';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import { ThemeSettings } from './ThemeSettings';
import { Box, Container } from '@mui/material';

const _theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // プライマリカラー（例：青）
    },
    secondary: {
      main: '#dc004e', // セカンダリカラー（例：赤）
    },
    error: {
      main: '#f44336', // エラーカラー（例：赤）
    },
    background: {
      default: '#f5f5f5', // 背景色
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
});

const Theme = (props) => {
  return (
    <ThemeProvider theme={_theme}>
      <CssBaseline />
      <Box {...ThemeSettings.header}></Box>
      <Box justifyContent="center" sx={{ my: 10 }}>
        <Container maxWidth="lg">{props.children}</Container>
      </Box>
      <Box {...ThemeSettings.footer}></Box>
    </ThemeProvider>
  );
};

export default Theme;
