import React from 'react';
import Header from './Header';
import Footer from './Footer';

export const ThemeSettings = {
  header: {
    children: <Header />,
    sx: {},
  },
  body: {
    outcolor: 'red',
  },
  footer: {
    children: <Footer />,
    sx: {},
  },
};
